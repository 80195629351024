.group-sc {
    .row-signer {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;
        .input-sc {
            width: 100%;
            .f-control-round {
                width: calc(100% - 10%);
            }
        }
        .checked-sc {
            width: 8%;
            margin-left: auto;
            text-align: right;
            cursor: pointer;
            &.row {
                .anticon-check-circle {
                    width: 40px;
                    height: 40px;
                    background: #cd4c9c5c;
                    padding: 10px;
                    border-radius: 10px;
                    svg {
                        font-size: 20px;
                    }
                }
            }
            .anticon-check-circle {
                width: 40px;
                height: 40px;
                background: #18b318;
                padding: 10px;
                border-radius: 10px;
                svg {
                    font-size: 20px;
                }
            }
            .anticon-edit {
                width: 40px;
                height: 40px;
                background: #18b318;
                padding: 10px;
                border-radius: 10px;
                svg {
                    font-size: 20px;
                }
            }
        }
    }
    .txt {
        font-family: "Poppins", sans-serif; 
        margin-bottom: 10px;
        font-weight: 500;
        font-style: normal;
        font-size: 16px;
    }
    .input-sc {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .checked-sc {
            width: 10%;
            margin-left: auto;
            text-align: right;
            cursor: pointer;
            .anticon-check-circle {
                width: 40px;
                height: 40px;
                background: #18b318;
                padding: 10px;
                border-radius: 10px;
                svg {
                    font-size: 20px;
                }
            }
            .anticon-edit {
                width: 40px;
                height: 40px;
                background: #18b318;
                padding: 10px;
                border-radius: 10px;
                svg {
                    font-size: 20px;
                }
            }
        }
        .f-control-round {
            width: calc(100%);
            .f-control-sc {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                border: 0px solid transparent;
                padding: 10px;
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                font-size: 14px;
                border: 1px solid rgba(128, 128, 128, 0.56);
                width: -webkit-fill-available;
                &:focus {
                    outline: none;
                }
                // &.dis {
                //     background: #717488;
                //     color: #fff;
                //     pointer-events: none;
                //     cursor: no-drop;
                // }
            }
        }
    }
    .withdraw-sc {
        margin-top: 20px;
        padding: 15px;
        border-radius: 8px;
        background: rgba(205, 76, 156, 0.17); 
        .name {
            font-family: "Poppins", sans-serif; 
            margin-bottom: 20px;
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
        }
        .row-btn-wt {
            margin-top: 20px;
            text-align: center;
            .btn-wh {
                font-family: "Poppins", sans-serif;
                background: #cd4c9c;
                font-weight: 500;
                color: #fff;
                font-style: normal;
                border: 2px solid #c2bfbf;
                width: -moz-fit-content;
                width: fit-content;
                padding: 7px 15px;
                border-radius: 99px;
                height: 40px;
                width: 180px;
                cursor: pointer;
                &.dis {
                    background: #cd4c9c67;
                    cursor: no-drop;
                }
            }
        }
        .input-withraw {
            position: relative;
            .btn-max {
                position: absolute;
                height: 34px;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 6px;
                border: 0px solid transparent;
                background: #26348c;
                color: #fff;
                font-family: "Poppins", sans-serif; 
                font-weight: 500;
                cursor: pointer;
            }
            .f-control-wt {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                border: 0px solid transparent;
                padding: 10px;
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                font-size: 14px;
                border: 1px solid rgba(128, 128, 128, 0.56);
                width: -webkit-fill-available;
                &:focus {
                    outline: none;
                }
            }
        }
    } 
    .pool-contract {
        margin-top: 20px;
        padding: 15px;
        border-radius: 8px;
        background: rgba(205, 76, 156, 0.17);
        .name {
            font-family: "Poppins", sans-serif; 
            margin-bottom: 20px;
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
        }
        .item {
            display: flex;
            flex-wrap: wrap;
            font-family: "Poppins", sans-serif; 
            margin-bottom: 20px;
            font-weight: 500;
            font-style: normal;
            font-size: 14px;
            .anticon-play-circle {
                width: 30px;
                height: 30px;
                background:#18b318;
                padding: 7px;
                border-radius: 7px;
                cursor: pointer;
                svg {
                    font-size: 15px;
                }
            }
            .left {
                width: 30%;
            }
            .right {
                width: 70%;
                .anticon-reload {
                    margin-left: 10px;
                    width: 30px;
                    height: 30px;
                    background:#18b318;
                    padding: 7px;
                    border-radius: 7px;
                    cursor: pointer;
                    svg {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
.gen-sign-btn {
    background: #cd4c9c;
    color: #fff;
    font-family: "Poppins", sans-serif; 
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    border: 1px solid #fff;
    padding: 7px 15px;
    border-radius: 99px;
    cursor: pointer;
    &.dis {
        background: #cd4c9c73;
        border: 1px solid #ffffff8f;
        cursor: no-drop;
    }
}
.btn-copy {
    cursor: pointer;
    margin-left: 10px;
}
.title-connect {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 30px;
    text-align: center;
}
@media screen and (max-width: 768px) {
    .row-import .guide-import.res {
        width: 100%;
    }
    .row-import .guide-import {
        padding: 15px;
    }
    .group-sc .pool-contract .item .left {
        width: 60%;
    }
    .group-sc .pool-contract .item .right {
        width: 40%;
    }
}