@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.w-6 {
    width: 50%;
}

.columns {
    display: flex;
    flex-wrap: wrap;
}

.wrap-login {
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #9053c7;
    background-image: url("./images/back.jpg");
    background-position: bottom;
}

.row-login {
    width: 960px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    justify-content: space-between;
    padding: 120px 30px;

    .content-row {
        .box-img {
            img {
                transform: scale(1);
                transition: 0.3s ease-in-out;
            }
        }
        &:hover img {
            transform: scale(1.2);
            transition: 0.3s ease-in-out;
        }
        .form-login {
            .title {
                font-family: "Poppins", sans-serif;
                font-weight: 700;
                font-style: normal;
                font-size: 28px;
                color: #333;
                line-height: 1.2;
                text-align: center;
                width: 100%;
                display: block;
                padding-bottom: 40px;
            }

            .input-form {
                margin-bottom: 10px;
                text-align: center;

                .form-control {
                    margin: 0 auto;
                    width: 300px;
                    height: 40px;
                    font-family: "Poppins", sans-serif;
                    font-weight: 500;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 1.5;
                    color: #666;
                    display: block;
                    background: #e6e6e6;
                    height: 50px;
                    border-radius: 25px;
                    padding: 0 15px 0 15px;
                    border: 0px solid transparent;
                }

                .btn-login {
                    font-family: "Poppins", sans-serif;
                    margin: 0 auto;
                    margin-top: 20px;
                    font-weight: 600;
                    font-style: normal;
                    font-size: 15px;
                    line-height: 1.5;
                    color: #fff;
                    text-transform: uppercase;
                    width: 300px;
                    height: 50px;
                    border-radius: 25px;
                    background: linear-gradient(180deg,  #BD56A0  0%,  #652985  71%);
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 25px;
                    -webkit-transition: all .4s;
                    -o-transition: all .4s;
                    -moz-transition: all .4s;
                    transition: all .4s;
                    border: 0px solid transparent;
                    cursor: pointer;
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .w-6 {
        width: 100%;
    }
    .row-login {
        padding: 50px 20px;
    }
    .content-row {
        margin-top: 20px;
    }
}