.row-import {
    padding: 50px 0;
    .ant-pagination {
        margin-top: 20px;
        text-align: center;
        .ant-pagination-item {
            &.ant-pagination-item-active {
                a {
                    color: #000;
                }
            }
            a {
                color: #fff;
            }
        }
        svg {
            fill: #fff;
        }
    }
    .guide-import {
        width: 1000px;
        margin: 0 auto;
        background: linear-gradient(180deg, #27358F 0%, #161041 100%);
        border-radius: 10px;
        padding: 40px 30px;
        color: #fff;
        &.res {
            width: 800px;
        }
        &.row {
            width: 1200px;
        }
        .title {
            text-align: center;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 28px;
            margin-bottom: 20px;
        }
        .row-add-wl {
            text-align: center;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .example-wl {
                width: 30%;
                text-align: left;
                padding-top: 10px;
                a {
                    font-family: "Poppins", sans-serif;
                    font-weight: 500;
                    color: #fff;
                    font-style: normal;
                    svg {
                        margin-left: 5px;
                    }
                }
            }
            .row-choose-file {
                width: 40%;
            }
            .row-start-up {
                width: 30%;
                text-align: right;
            }
            .ant-btn {
                font-family: "Poppins", sans-serif;
                background: #cd4c9c;
                font-weight: 500;
                color: #fff;
                font-style: normal;
                border: 2px solid #c2bfbf;
                width: -moz-fit-content;
                width: fit-content;
                padding: 7px 15px;
                border-radius: 99px;
                height: 40px;
                width: 180px;
            }
            .ant-upload-list-item {
                background: #181448;
                font-weight: 500;
                font-size: 14px;
                padding: 10px;
                border-radius: 8px;
                color: #fff;
                font-family: "Poppins", sans-serif;
                margin-top: 15px;
                svg {
                    fill: #fff;
                }
            }
        }
        .row-data-wl {
            position: relative;
            .text {
                text-align: left;
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 20px;
                margin-top: 20px;
                border-top: 1px solid #ffffff65;
                padding-top: 20px;
            }
            .ant-empty-description {
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                font-size: 16px;
                color: #fff;
            }
        }
    }
}
.checked {
    font-size: 20px;
    cursor: pointer;
    svg {
        fill: #13cc13;
    }
}

.closed {
    font-size: 20px;
    cursor: pointer;
    svg {
        fill: red;
    }
}

.round-add-by-hand {
    .columns {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .w-3 {
            width: 49%;
            .row-input {
                margin-bottom: 15px;
                width: 100%;
                .round-name {
                    font-family: "Poppins", sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    color: #fff;
                    margin-bottom: 10px;
                }
                .f-control {
                    width: 100%;
                    height: 38px;
                    border-radius: 8px;
                    border: 0px solid transparent;
                    padding: 10px;
                    font-family: "Poppins", sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                }
                .row-select {
                    width: 100%;
                    .ant-select {
                        width: 100%;
                        height: 38px;
                        .ant-select-selector {
                            height: 40px;
                            .ant-select-selection-item {
                                font-family: "Poppins", sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    .gr-button {
        text-align: center;
        .btn-edit {
            font-family: "Poppins", sans-serif;
            background: #cd4c9c;
            font-weight: 500;
            font-style: normal;
            border: 2px solid #c2bfbf;
            width: -moz-fit-content;
            width: fit-content;
            padding: 7px 15px;
            border-radius: 99px;
            color: #fff;
            width: 150px;
            margin: 0 auto;
            margin-top: 10px;
            cursor: pointer;
            &.dis {
                background: #cd4c9c2b;
                cursor: no-drop;
            }
        }
    }
}
.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.9) !important;
}
.modal-edit {
    .ant-modal-title {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        text-align: center;
        margin-bottom: 40px;
    }
    .ant-modal-content {
        .ant-modal-footer {
            display: none;
        }
        .row-modal-add-wl {
            .item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                .txt {
                    width: 25%;
                    text-align: left;
                    font-family: "Poppins", sans-serif;
                    font-size: 15px;
                }
                .row-select {
                    width: 70%;
                    .ant-select {
                        width: 100%;
                        height: 40px;
                        .ant-select-selector {
                            height: 40px;
                            .ant-select-selection-item {
                                font-family: "Poppins", sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .input-modal {
                    width: 70%;
                    .f-controls {
                        width: 100%;
                        height: 20px;
                        border-radius: 8px;
                        border: 0px solid transparent;
                        padding: 10px;
                        font-family: "Poppins", sans-serif;
                        font-weight: 500;
                        font-size: 14px;
                        border: 1px solid rgba(128, 128, 128, 0.56);
                        width: -webkit-fill-available;
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
            .gr-button {
                text-align: center;
                .btn-edit {
                    font-family: "Poppins", sans-serif;
                    background: #cd4c9c;
                    font-weight: 500;
                    font-style: normal;
                    border: 2px solid #c2bfbf;
                    width: -moz-fit-content;
                    width: fit-content;
                    padding: 7px 15px;
                    border-radius: 99px;
                    color: #fff;
                    width: 150px;
                    margin: 0 auto;
                    cursor: pointer;
                    &.dis {
                        background: #cd4c9c2b;
                        cursor: no-drop;
                    }
                }
            }
        }
    } 
}
.round-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .row-search {
        width: 40%;
    }
    .row-select {
        width: 20%;
        .ant-select {
            width: 100%;
            height: 40px;
            .ant-select-selector {
                height: 40px;
                .ant-select-selection-item {
                    font-family: "Poppins", sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }
}
.text-chain {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
}
@media screen and (max-width: 768px) {
    .row-import .guide-import {
        width: 100% !important;
    }
    .row-import .guide-import .row-add-wl {
        width: 100%;
    }
    .row-import .guide-import .row-add-wl .example-wl {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
    .row-import .guide-import .row-add-wl .row-choose-file {
        width: 100%;
        margin-bottom: 20px;
    }
    .row-import .guide-import .row-add-wl .row-start-up {
        width: 100%;
        text-align: center;
    }
    .round-add-by-hand .columns .w-3 {
        width: 100%;
    }
    .round-filter .row-search {
        width: 100%;
        margin-bottom: 10px;
    }
    .round-filter .row-select {
        width: 100%;
    }
    .round-filter {
        flex-wrap: wrap;
    }
    .data-table {
        overflow-y: scroll;
        margin-top: 20px;
    }
    .main-table {
        width: 1000px;
    }
}
.non-data {
    .ant-empty-description {
        color: #fff;
        font-family: "Poppins", sans-serif;
    }
}