.header {
    .main-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            .box-logo {
                img {
                    width: 120px;
                }
            }
        }
    }
}
.main-menu {
    height: 100vh;
    background: linear-gradient(180deg, #27358F 0%, #161041 100%);
    .box-logo {
        padding: 20px 0 40px 0;
        img {
            width: 140px;
        }
    }
    .ant-menu {
        background: transparent;
        .ant-menu-item {
            color: #fff;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-style: normal;
            text-align: left;
            &.ant-menu-item-selected {
                color: #000b7a;
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                font-style: normal;
            }
        }
    }
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    .flex-content {
        width: calc(100% - 257px);
        position: relative;
        z-index: 3;
        &::before {
            content: "";
            background-image: url("../components/main/images/back.jpg");
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 1;
            z-index: 2;
            background-position: bottom;
        }
        &::after {
            content: "";
            background:#000;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0.5;
            z-index: 2;
        }
    }
    .flex-content-row {
        padding: 10px;
        text-align: left;
        height: 100vh;
        overflow-y: auto;
        padding-bottom: 100px;
        .layout-row {
            position: relative;
            z-index: 3;
            padding: 10px;
            border-radius: 8px;
        }
    }
}

.role-admin {
    padding: 15px 10px;
    background: linear-gradient(90deg, #27358F 0%, #161041 100%);
    color: #fff;
    width: 100%;
    text-align: right;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 999;
    border-bottom: 1px solid #504b4b;
    .name-role {
        font-family: "Poppins", sans-serif;
        background: #cd4c9c;
        font-weight: 500;
        font-style: normal;
        border: 2px solid #c2bfbf;
        width: fit-content;
        padding: 7px 15px;
        border-radius: 99px;
        .anticon svg {
            font-size: 18px;
        }
    }
    .logout-role {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        background: #cd4c9c;
        font-style: normal;
        border: 2px solid #c2bfbf;
        width: fit-content;
        width: fit-content;
        padding: 8px 10px;
        border-radius: 99px;
        margin-left: 10px;
        line-height: 1.2;
        cursor: pointer;
        .anticon svg {
            font-size: 18px;
        }
    }
    .wallet-adapter-dropdown {
        margin-right: 10px;
        margin-left: auto;
        .wallet-adapter-button {
            background: transparent;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-style: normal;
            border: 2px solid #c2bfbf;
            width: -moz-fit-content;
            background: #cd4c9c;
            width: fit-content;
            padding: 7px 15px;
            border-radius: 99px;
            height: 38.25px;
            font-size: 13px;
        }
    }
    .login-role {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-style: normal;
        border: 1px solid #c2bfbf;
        width: fit-content;
        margin-left: auto;
        padding: 7px 15px;
        border-radius: 99px;
        margin-left: auto;
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {
    .login-role {
        margin-left: 0 !important;
    }
    .flex-row .flex-content {
        width: 100%;
    }
    .row-import .guide-import.res {
        width: 100% !important;
    }
    .role-admin .wallet-adapter-dropdown {
        margin-left: 0px;
    }
    .flex-row {
        position: relative;
    }
    .flex-row .flex-content-row {
        padding: 0;
        padding-bottom: 50px;
    }
}
.btn-bar {
    font-family: "Poppins", sans-serif;
    position: absolute;
    right: 15px;
    top: 15px;
    background: #cd4c9c;
    font-weight: 500;
    font-style: normal;
    border: 2px solid #c2bfbf;
    width: fit-content;
    padding: 9px 10px;
    border-radius: 99px;
    width: 40px;
    height: 40px;
    color: #fff;
    z-index: 99;
    .anticon svg {
        font-size: 18px;
    }
}
.menu-drawer {
    .ant-drawer-body {
        padding: 0;
    }
    .main-menu {
        padding: 20px;
    }
}
.ant-drawer-content-wrapper {
    width: 100% !important;
}
.ant-drawer-close {
    position: absolute;
    right: 15px;
    top: 15px;
}