.box-round {
    padding: 15px;
    border-radius: 8px;
    background: rgb(205 76 156 / 17%);
    .ant-empty-description {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        color: #fff;
        font-size: 16px;
        padding-bottom: 5px;
    }
    .txt {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        color: #fff;
        font-size: 16px;
        padding-bottom: 15px;
    }
    .item {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        .text {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            color: #fff;
            font-size: 14px;
            padding-bottom: 10px;
        }
        .form-input {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
                width: 48%;
                position: relative;
                .name-balance {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-family: "Poppins", sans-serif;
                    font-weight: 500;
                    color: #000;
                    font-size: 14px;
                }
            }
            .right {
                width: 48%;
                position: relative;
                .name-balance {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-family: "Poppins", sans-serif;
                    font-weight: 500;
                    color: #000;
                    font-size: 14px;
                }
            }
            .f-form {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                border: 0px solid transparent;
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                color: #000;
                padding: 10px;
            }
            .ant-picker {
                width: 100%;
                height: 40px;
                border-radius: 8px;
            }
        }
    }
    .gr-button {
        text-align: center;
        .btn-edit {
            font-family: "Poppins", sans-serif;
            background: #cd4c9c;
            font-weight: 500;
            font-style: normal;
            border: 2px solid #c2bfbf;
            width: -moz-fit-content;
            width: fit-content;
            padding: 7px 15px;
            border-radius: 99px;
            color: #fff;
            width: 150px;
            margin: 0 auto;
            margin-top: 10px;
            cursor: pointer;
            &.dis {
                background: #cd4c9c2b;
                cursor: no-drop;
            }
        }
    }
}
.main-table {
    border-collapse: collapse;
    width: 100%;
    tr {
        th {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #fff;
            padding: 18px 15px;
            text-align: left;
            border-bottom: 1px solid #504b4b;
        }
        td {
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #fff;
            padding: 12px 15px;
            text-align: left;
            border-bottom: 1px solid #504b4b;
        } 
    }
}
.btn-action {
    background: #0eb10e;
    border: 0px solid transparent;
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}
.back-setting {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    background: #000;
    width: fit-content;
    padding: 5px 10px;
    background: #cd4c9c;
    border: 1px solid #fff;
    position: absolute;
    left: 15px;
    top: 15px;
    border-radius: 8px;
    cursor: pointer;
}
.guide-import {
    position: relative;
}
@media screen and (max-width: 768px) {
    .box-round .item .form-input .right {
        width: 100%;
        margin-bottom: 10px;
    }
    .box-round .item .form-input .left {
        width: 100%;
    }
    .box-round .item .form-input {
        flex-wrap: wrap;
    }
}